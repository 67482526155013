import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { testimonials } from '../../data/testimonials.data';

@Component({
  selector: 'pg-testimonials',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './testimonials.component.html',
  styleUrls: ['testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  testimonials = testimonials;
  constructor(config: NgbCarouselConfig, private modalService: NgbModal) {
    config.interval = 4000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
  }
  ngOnInit() {}

  open(modal: any) {
    this.modalService.open(modal, { size: 'xl' })
  }
}
