import { ITestimonials } from '../models/testimonials.model';

export const testimonials: ITestimonials[] = [
  {
    text: 'Plasma Games does a phenomenal job integrating standards with engaging, differentiated activities, while exposing students to real-world STEM careers and technology, all while "hooking" students with an exciting 3-D video game that makes it impossible not to learn. The platform makes wise use of every minute of instructional time.',
    author: 'Darla Harbin, School Improvement',
  },
  {
    text: 'Plasma Games holds attention, motivates, and engages students in a unique way in which traditional methods fall short. Many students don\'t believe that they can "do" STEM. Plasma Games convinces them that the skills are obtainable while motivating and facilitating a deep understanding of the material.',
    author: 'Carmen Garland, Teaching and Learning',
  },
  {
    text: "Plasma Games is necessary to engage modern students. Plasma Games' applications of learning standards in real world technologies provide students' purpose and meaning to their STEM learning and changed their attitudes towards pursuing a career in STEM.",
    author: 'Kenyatta Aldridge, Science Coach',
  },
  {
    text: 'Motivation and participation is higher in my students because of Plasma Games.',
    author: 'Grace Yusckat, Science Teacher',
  },
  {
    text: 'Plasma Games is revolutionizing educational gaming. I have never seen a more inclusive, impressive, or engaging teaching tool!',
    author: 'David Madorin Science Teacher',
  },
];
