<div class="container text-center">
  <h1 class="display-4 text-secondary">Testimonials</h1>
  <ngb-carousel *ngIf="testimonials">
    <ng-template ngbSlide *ngFor='let testimonial of testimonials'>
      <div class="testimonial">
        <div class="testimonial-text">{{testimonial.text}}</div>
        <p class="small testimonial-author">{{testimonial.author}}</p>
      </div>
    </ng-template>
  </ngb-carousel>
  <button data-aos="fade-in" type="button" class="btn btn-secondary" (click)="open(testimonialsModal)">
    View More
  </button>
</div>
<ng-template #testimonialsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-testimonialsModal-title">More Testimonials</h4>
    <button type="button" class="ms-2 mb-1 btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe class="rounded" src="https://dkmzd4tgwtjws.cloudfront.net/public/files/testimonials-digital.pdf" width="100%" height="700px"></iframe>
  </div>
</ng-template>