import { TestimonialsComponent } from './testimonials/testimonials.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';
import { AboutUsTeamMemberComponent } from './about-us-team-member/about-us-team-member.component';
import { CareerCardComponent } from './career-card/career-card.component';

export const components = [TestimonialsComponent, BannerCarouselComponent, AboutUsTeamMemberComponent, CareerCardComponent];

export * from './testimonials/testimonials.component';
export * from './banner-carousel/banner-carousel.component';
export * from './about-us-team-member/about-us-team-member.component';
export * from './career-card/career-card.component';
